<template>
  <div>
    <div class="dis jus_content">
      <div class="flex align_center">
        <div class="icon"></div>
        <div class="service">企业动态</div>
      </div>
      <div class="add" style="" @click="adddynamic()">添加企业动态</div>
    </div>

    <el-table :data="tableData" style="width: 100%">
      <el-table-column prop="id" label="ID" min-width="100" align="center">
      </el-table-column>
      <el-table-column
        prop="title"
        label="标题"
        min-width="180"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="create_time"
        label="添加时间"
        min-width="180"
        align="center"
      >
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="props">
          <!-- <el-button type="text">编辑</el-button> -->
          <el-button type="text" @click="amend(props.row.id)">编辑</el-button>
          <el-button type="text" @click="del(props.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
      title="添加企业动态"
      :visible.sync="setcountlogin"
      width="600px"
      center
    >
      <div>
        <div class="flex align_center marb_20">
          <div class="font_20" style="width: 100px">荣誉标题</div>
          <el-input v-model="dynamictitle"></el-input>
        </div>
        <el-upload
          ref="uploadList"
          list-type="picture-card"
          class="avatar-uploader"
          action="#"
          accept=".jpg,.jpeg,.png"
          :multiple="true"
          name="file2"
          :limit="9"
          :auto-upload="true"
          :file-list="uploadList"
          :http-request="httpreq"
          :before-upload="changeUpload"
          :on-exceed="handleExceed"
          :on-remove="handleRemove"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
      </div>
      <div class="flex mat_30">
        <div class="enter" @click="dynamicadd()">确认添加</div>
        <div class="marl_30 cancel" @click="setcountlogin = false">取消</div>
      </div>
    </el-dialog>

    <el-dialog
      title="修改企业动态"
      :visible.sync="amendlogin"
      width="600px"
      center
    >
      <div>
        <div class="flex align_center marb_20">
          <div class="font_20" style="width: 100px">标题</div>
          <el-input v-model="amendtitle"></el-input>
        </div>

        <div class="flex">
          <!-- <div 
            v-for="(item, index) in amendimg" :key="item.id"
            class="flex"
            style="margin-right: 20px"
          >
            <img
              :src="item.img"
              style="width: 148px; height: 148px"
              alt=""
              v-if="uploading == false"
            />
            <div
              style="position: relative; transform: translate(-20px, 5px)"
              v-if="uploading == false"
              @click="delimg(item)"
            >
              <i class="el-icon-close"></i>
            </div>
          </div> -->

          <el-upload
            ref="uploadList"
            list-type="picture-card"
            class="avatar-uploader"
            action="#"
            accept=".jpg,.jpeg,.png"
            :multiple="true"
            name="file2"
            :limit="9"
            :auto-upload="true"
            :file-list="amendimg"
            :http-request="uphttpreq"
            :before-upload="changeUpload"
            :on-exceed="handleExceed"
            :on-remove="(file, fileList) => uphandleRemove(file, fileList)"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </div>
      </div>
      <div class="flex mat_30">
        <div class="enter" @click="dynamicup()">确认修改</div>
        <div class="marl_30 cancel" @click="amendlogin = false">取消</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dynamiclist: [],
      tableData: [],
      uploadList: [],
      imgList: [],
      allTableData: [],
      dynamictitle: "",
      setcountlogin: false,
      paginations: {
        page_index: 1, // 当前位于哪页
        total: 10, // 总数
        page_size: 10, // 1页显示多少条
        page_sizes: [10, 20, 50, 90], //每页显示多少条
        layout: "total, sizes, prev, pager, next, jumper", // 翻页属性
      },

      //修改
      amend_Id: "",
      amendtitle: "",
      amendimg: [],
      amendloading: false,
      amendlogin: false,
      uploading: false, // 是否有重新上传
      updynamiclist: [],
      upimgList: [],
      lookamendimg: [],

      imgArr: [],
    };
  },
  created() {
    this.getnews();
    // this.authentication();
  },

  methods: {
    // 认证
    authentication() {
      let data = {
        token: localStorage.eleToken,
      };
      console.log(data, "data");
      this.$get("/enterprise_user_status", data).then((res) => {
        console.log(res, "res456");
        if (res.status === 1) {
          this.setcountlogin = true;
        } else if (res.status === 2) {
          setInterval(() => {
            // location.href = "http://sso.zhenchangkj.com:9000/realname/index";
            // location.href = "http://sso.smeqh.cn:9000/realname/index";
            this.$message({
              type: "info",
              message: "您的账号还未实名认领，请认领通过之后再进行下一步操作",
            });
          }, 2000);
        }
      });
    },
    //修改获取之前填写信息
    amend(id) {
      this.amend_Id = id;
      let data = {
        token: localStorage.eleToken,
        id: id,
      };
      
      this.$get("info_news_lst_d", data).then((res) => {
        this.amendlogin = true;
        this.amendtitle = res.data.title;
        let list = JSON.parse(res.data.img);
        console.log(list, "list");

        this.lookamendimg = list.map((item) => {
          console.log(item, "item");
          return {
            name: item.img,
            url: item.img ? item.img : item,
            img: item.img,
          };
        });

        //展示
        this.amendimg = list.map((item) => {
          return {
            name: item.path,
            url: item.img ? item.img : item,
          };
        });

        console.log(this.lookamendimg, "lookamendimg");
        console.log(this.amendimg, "amendimg");
      });
    },

    //修改
    dynamicup() {
      if (this.amendtitle == "") {
        this.$message({
          type: "info",
          message: "请输入荣誉标题",
        });
      } else {
        if (this.uploading == false) {
          let data = {
            token: localStorage.eleToken,
            title: this.amendtitle,
            img: this.lookamendimg,
            id: this.amend_Id,
          };
          console.log(data, "updata111");

          this.$post("info_news_upd", data).then((res) => {
            this.amendlogin = false;
            this.amendloading = false;
            this.$message({
              type: "success",
              message: res.msg,
            });
            this.getnews();
            console.log(res, "res");
          });
        } else {
          Promise.all(this.getupimgArr())
            .then((result) => {

              let data = {
                token: localStorage.eleToken,
                title: this.amendtitle,
                img: this.lookamendimg.concat(result),
                id: this.amend_Id,
              };
              console.log(data, "updata777");

              this.$post("info_news_upd", data).then((res) => {
                this.amendlogin = false;
                this.amendloading = false;
                this.$message({
                  type: "success",
                  message: res.msg,
                });
                this.getnews();
                console.log(res, "res");
              });
            })
            .catch((error) => {
              console.log("error==>", error);
            });
        }
      }
    },

    getimgArr() {
      // this.dynamiclist.forEach((item) => {

      //   console.log(this.upimg(item),'this.upimg(item)')
      //   this.imgArr.push(this.upimg(item))

      // });
      // console.log(imgArr,'imgArr')
      // return imgArr

      let arrUrl = [];
      for (let i = 0; i < this.dynamiclist.length; i++) {
        arrUrl.push(this.upimg(this.dynamiclist[i]));
      }
      console.log(arrUrl, "arrUrl");
      return arrUrl;
    },
    getupimgArr(){
      let uparrUrl = [];
      for (let i = 0; i < this.updynamiclist.length; i++) {
        uparrUrl.push(this.upamendimg(this.updynamiclist[i]));
      }
      console.log(uparrUrl, "uparrUrl");
      return uparrUrl;
    },

    dynamicadd() {
      if (this.dynamictitle == "") {
        this.$message({
          type: "info",
          message: "请输入荣誉标题",
        });
      } else {
        Promise.all(this.getimgArr())
          .then((res) => {
            console.log(res, "respromise");
            let data = {
              token: localStorage.eleToken,
              title: this.dynamictitle,
              img: res,
            };
            console.log(data, "info_news_adddata");
            this.$post("info_news_add", data).then((res) => {
              this.loading = false;
              this.setcountlogin = false;
              this.$message({
                type: "success",
                message: res.msg,
              });
              this.getnews();
              console.log(res, "res");
            });
          })
          .catch((error) => {
            console.log("error==>", error);
          });
      }
    },
    //删除
    del(id) {
      let data = {
        token: localStorage.eleToken,
        id: id,
      };
      console.log(data, "updata");

      this.$post("info_news_del", data).then((res) => {
        this.$message({
          type: "success",
          message: res.msg,
        });
        this.getnews();
      });
    },

    //上传图片
    upimg(item) {
      // this.dynamiclist.map((item) => {
      return new Promise((resolve, reject) => {
        let formdata = new FormData();
        formdata.append("file", item);
        this.$post("upload_img", formdata).then((res) => {
          console.log(res, "res");
          resolve({
            path: res.path,
            img: res.address,
            url: res.address,
          });

          // this.imgList.push({ path: res.path, img: res.address,url: res.address, });
          // this.imgList = res.map({
          //   return: {
          //     path: res.path,
          //     img: res.address,
          //     url: res.address,
          //   },
          // });
        });
      });
      // });
    },

    //上传图片
    upamendimg(item) {
      // console.log(this.updynamiclist, "updynamiclist");
      // return new Promise((resolve, reject) => {
      //   this.amendloading = true;
      //   this.updynamiclist.map((item) => {
      //     let formdata = new FormData();
      //     formdata.append("file", item);
      //     this.$post("upload_img", formdata).then((res) => {
      //       console.log(res, "res");
      //       console.log(this.lookamendimg, "this.lookamendimg111");
      //       this.lookamendimg.push({
      //         path: res.path,
      //         url: res.address,
      //         img: res.address,
      //       });
      //       console.log(this.lookamendimg, "this.lookamendimg22");
      //     });
      //   });
      //   resolve();
      // });

      return new Promise((resolve, reject) => {
        let formdata = new FormData();
        formdata.append("file", item);
        this.$post("upload_img", formdata).then((res) => {
          console.log(res, "res");
          resolve({
            path: res.path,
            img: res.address,
            url: res.address,
          });
        });
      });

    },

    adddynamic() {
      this.dynamictitle = ""
      this.uploadList = []
      this.authentication();
    },

    getnews(item) {
      let data = {
        token: localStorage.eleToken,
        page: item ? item : this.paginations.page_index,
        limit: this.paginations.page_size,
      };
      console.log(data, "data");
      this.$post("info_news_lst", data).then((res) => {
        this.allTableData = res.data;
        console.log(this.allTableData, " this.allTableData ");
        if (res.count === "") {
          this.paginations.total = 0;
        } else {
          this.paginations.total = res.count;
        }
        if (this.allTableData.length != 0) {
          this.tableData = this.allTableData.filter((item, index) => {
            return index < this.paginations.page_size;
          });
        } else {
          this.tableData = [];
        }
      });
    },

    // 分页
    handleSizeChange(page_size) {
      console.log(page_size, "page_size");
      this.paginations.page_index = 1;
      this.paginations.page_size = page_size;
      this.getnews();
      this.tableData = this.allTableData.filter((item, index) => {
        return index < page_size;
      });
    },
    handleCurrentChange(page) {
      console.log(page, "handleCurrentChangepage");
      //获取当前页
      let index = this.paginations.page_size * (page - 1);
      //获取数据总数
      let nums = this.paginations.page_size * page;
      this.getnews(page);
      //容器
      let tables = [];
      for (let i = index; i < nums; i++) {
        if (this.allTableData[i]) {
          tables.push(this.allTableData[i]);
        }
        this.tableData = tables;
      }
    },

    httpreq(load) {
      this.dynamiclist.push(load.file);
      console.log(this.dynamiclist, "this.dynamiclist");
      //   let formdata = new FormData();
      //   formdata.append("file", load.file);
      //   this.$post("upload_img", formdata).then((res) => {
      //     this.uploadList.push(res.address);
      //     console.log(res, "res");
      //   });
    },
    uphttpreq(load) {
      this.updynamiclist.push(load.file);
      console.log(this.updynamiclist, "this.updynamiclist");
      this.uploading = true;
      //   let formdata = new FormData();
      //   formdata.append("file", load.file);
      //   this.$post("upload_img", formdata).then((res) => {
      //     this.uploadList.push(res.address);
      //     console.log(res, "res");
      //   });
    },
    changeUpload(file, fileList) {
      let testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension = testmsg === "jpg";
      const extension1 = testmsg === "jpeg";
      const extension2 = testmsg === "png";

      if (!extension && !extension1 && !extension2) {
        this.$message({
          message: "上传文件只能是.jpg、.jpeg、.png格式!",
          type: "warning",
          duration: 1500,
        });
        console.log(this.option, " this.option");
        this.img = "";

        this.headimg = "";
        this.uploadList = [];
        return false;
      }
      let formData = new FormData();

      formData.append("file", file.raw);
    },

    handleRemove(file, fileList) {
      this.dynamiclist = fileList;
    },

    uphandleRemove(file, fileList) {
      console.log(file, "file");
      console.log(fileList, "fileList");
      this.lookamendimg = fileList.map((item) => {
        return {
          name: item.name,
          url: item.url,
          img: item.url,
        };
      });
      let data = {
        token: localStorage.eleToken,
        img: file.url,
      };

      this.$post("info_news_img_del", data).then((res) => {
        this.$message({
          type: "success",
          message: res.msg,
        });
      });
    },
    // handleRemove() {
    //     console.log(this.uploadList,'this.uploadList')
    //     // this.uploadList.pop()
    // //   this.uploadList = [];
    // //   this.finance_trade = "";
    // },
    handleExceed() {
      this.$message({
        message: "最大上传数量为9张!",
        type: "warning",
        duration: 1500,
      });
    },
  },
};
</script>
<style>
.icon {
  width: 4px;
  height: 19px;
  background: #fb1010;
  border-radius: 2px;
}

.service {
  height: 30px;
  font-size: 24px;
  font-family: Source Han Sans SC Medium, Source Han Sans SC Medium-Medium;
  font-weight: 500;
  text-align: left;
  color: #333333;
  line-height: 30px;
  margin-left: 10px;
}
.add {
  width: 198px;
  height: 40px;
  background: #ff6618;
  border-radius: 9px;
  color: #fff;
  line-height: 40px;
  text-align: center;
}
.enter {
  width: 137px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  background: #ff6618;
  border-radius: 10px;
  color: #fff;
}

.cancel {
  width: 137px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  border-radius: 10px;
  background: #cfcfcf;
  color: #fff;
}
</style>
